<template>
  <div
    id="app"
    class="wrap"
  >
    <div
      class="flex overflow-hidden bg-gray-50 px-4"
    >
      <main
        class="flex-1 relative pb-8 z-0 overflow-y-auto overflow-x-hidden"
      >
        <div
          class="mt-8"
        >
          <slot />
        </div>
      </main>
    </div>
    <lazy-confirm-modal
      :is-modal-opened="isModalOpen"
      title="Terms and Conditions Updates"
      submit-button="Accept"
      submit-button-background-color="bg-cycle-600"
      submit-button-background-hover-color="hover:bg-cycle-500"
      back-button="Read T&C"
      @confirm-action="acceptTermsAndConditions"
      @close-confirm-modal="readTermsAndConditions"
      @close-modal="closeTermsAndConditions"
    >
      We’ve recently updated our T&C, please read and accept the new T&C to continue using the app.
    </lazy-confirm-modal>
  </div>
</template>

<script setup lang="ts">
import type { Ref } from 'vue'
import { onMounted, ref } from '#imports'
import { useUserStore } from '~/store/user'

const userStore = useUserStore()
const isModalOpen: Ref<boolean> = ref(false)
onMounted(() => {
  isModalOpen.value = !userStore.user?.t_c_accepted
})
const acceptTermsAndConditions = async (): Promise<void> => {
  await userStore.acceptTermsAndConditions()
  isModalOpen.value = false
}

const readTermsAndConditions = () => {
  let link
  switch (userStore.user?.country_code) {
    case 'DE':
      link = 'https://gethenry-public.s3.eu-central-1.amazonaws.com/agbs/germany.pdf'
      break
    case 'IT':
      link = 'https://gethenry-public.s3.eu-central-1.amazonaws.com/agbs/italy.pdf'
      break
    case 'AT':
      link = 'https://gethenry-public.s3.eu-central-1.amazonaws.com/agbs/austria.pdf'
      break
    case 'NL':
      link = 'https://cycle-public.s3.eu-central-1.amazonaws.com/agbs/nl.pdf'
      break
    default:
      link = 'https://gethenry-public.s3.eu-central-1.amazonaws.com/agbs/germany.pdf'
  }
  window.open(link, '_blank')
}

const closeTermsAndConditions = () => {
  if (!userStore.user) {
    return
  }
  userStore.user.t_c_accepted = true
  isModalOpen.value = false
}
</script>

<style>
.fade-enter-active,
.fade-leave-active {
    transition-duration: 0.3s;
    transition-property: opacity;
    transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
    opacity: 0
}
.wrap {
  padding-top: env(safe-area-inset-top);
}
.bg-special-blue {
  background-color: #EFF3FC;
}
</style>
